<!--商品场景-->
<template>
    <div class="mainBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-button type="primary" block size="small" @click="edit()">添加</el-button>
            </div>
        </div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px );">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column label="场景图片" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="domain + scope.row.pic" fit="cover" :preview-src-list="[domain + scope.row.pic]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="场景名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <!-- <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
        <div v-if="dialogTypeVisible">
            <el-dialog :title="title" :visible.sync="dialogTypeVisible" :modal-append-to-body="false"  :close-on-click-modal="false" width="600px">
                <el-form ref="formAuth" :rules="formRules" :model="formData"  size="small" label-position="top" class="alertForm">
                    <el-form-item label="场景名称" prop="name" style="width:100%">
                        <el-input v-model="formData.name" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="场景图标" prop="pic" style="width:100%">
                        <uploadImage :imageList = "formData.pic" :limitNum="limit" :clickFile="'pic'" @upload="uploadFile"></uploadImage>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogTypeVisible = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        
    </div>
</template>
<script>
    import {shopSceneListApi,shopSceneEditApi,shopSceneDelApi}  from '@/api/store.js';
    import uploadImage from '@/components/uploadImage';
    export default {
        data() {
            return {
                select:{},
                formData:{
                    pic:[]
                },
                selectData:[],//下拉选择列表
                list:[], //商品列表
                // previewList:[], //图片预览列表
                formRules:{
                    name: [{
                        required: true,
                        message: '请输入商品标题',
                        trigger: 'blur'
                    }],
                    pic: [{
                        required: true,
                        message: '请上传商品图片',
                        trigger: 'blur'
                    }],
                },

                title:'添加商品场景',

                dialogTypeVisible:false,

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
                limit:1, //商品主图上传个数限制
            }
        },
        components:{
            uploadImage, //图片上传
        },
        mounted() {
            this.getList();
        },
        methods: {
            //商品场景列表
            getList: function(){
                shopSceneListApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    // response.list.forEach(item => {
                    //     this.previewList.push(this.domain + item.pic);
                    // });

                })
            },
            uploadFile: function(file){
                this.formData.pic = file.pic;
            },
            //编辑商品
            edit: function(param = {}){
                this.dialogTypeVisible = true;
                //判断添加商品or编辑商品
                if(typeof param.id == 'undefined'){
                    this.title = '添加商品场景';
                    this.formData = {
                        pic:[]
                    }
                }else {
                    this.title = '编辑商品场景';
                    this.formData = JSON.parse(JSON.stringify(param));
                    this.formData.pic = [{
                        url:this.formData.pic
                    }]
                }
            },
            //删除商品
            deletes:function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    shopSceneDelApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{

                })
            },
            submit: function(){
                this.$refs['formAuth'].validate((valid) => {
                    if (valid) {
                        shopSceneEditApi({
                            id:this.formData.id,
                            name:this.formData.name,
                            pic:this.formData.pic[0].url
                        }).then(()=>{
                            this.dialogTypeVisible = false;
                            this.$message({
                                type: 'success',
                                message: '已保存!'
                            });
                            this.getList();
                        })
                    }
                })
            },
            
            

            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>